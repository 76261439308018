.navigation{display:flex;flex:0 0 70px;background:rgb(16,107,214);flex-direction:column;}
    .navigation .section{display:flex;flex:1;width:100%;}
    .navigation .section.box{flex:0 70px;}
    .navigation .section.links{margin:10px 0;}
        .navigation .section a{display:flex;flex:1;text-decoration:none;color:rgb(193, 220, 253);text-align:center;}
        .navigation .section a:hover{color:#FFF;}
            .navigation .section a svg{margin:auto;font-size:18pt;}

    /*Logo Override*/
    .navigation .section.logo a{color:#FFF;}

    /*Navigation Links*/
    .navigation ul{flex-direction:column;display:flex;flex:1;align-content:center;justify-content:center;overflow:hidden;}
        .navigation ul li.section{flex:0 70px;}
        .navigation ul li.section a{border-left:4px solid transparent;transition:all 0.5s;}
        .navigation ul li.section a.active{border-left:4px solid #FFF;color:#FFF;}
        .navigation ul li.section .bounce{animation-duration: 0.5s;animation-iteration-count:1;transform-origin: left;animation-name: nav-bounce;}
        @keyframes nav-bounce {0% {opacity: 0;transform: translateX(-2000px);}60% {opacity: 1;transform: translateX(30px);}80% {transform: translateX(-10px);}100% {transform: translateX(0);}}






/* .navigation{display:block;z-index:1000;}
    .navigation .main{display:block;position:fixed;top:0;left:0;width:100%;height:60px;background:#FFF;border-bottom:1px solid #DDD;} */

        /*Root Avatar*/
        /* .navigation .main > .avatar{display:block;float:left;height:30px;width:30px;padding:5px;background:rgb(43,136,192);border-radius:20px;margin:10px 15px 0 15px;line-height:30px;text-align:center;color:#FFF;} */
        
        /*Company controls*/
        /* .navigation .main .company{display:block;position:relative;float:left;height:60px;background:#FFF;color:#555;margin:0 0;}
        .navigation .main .company:hover{background:rgb(38, 121, 173);color:#FFF;}
            .navigation .main .company > span{display:block;clear:both;height:22px;padding:19px 40px 19px 15px;line-height:22px;font-size:10pt;font-weight:300;width:200px;cursor:pointer;}
            .navigation .main .company > svg{display:block;position:absolute;top:22px;right:12px;width:20px;height:16px;line-height:22px;text-align:center;transition:all 0.3s;}
            .navigation .main .company:hover > svg{transform:rotate(180deg);}
            .navigation .main .company ol{display:none;position:absolute;left:0;top:60px;background:#FFF;border:1px solid #ccced2;width:calc(100% - 2px);border-radius:0 0 3px 3px;}
            .navigation .main .company:hover ol{display:block;}
                .navigation .main .company ol li{display:block;clear:both;padding:15px;}
                .navigation .main .company ol li.line{padding:0;height:1px;background:#ccced2;}
                .navigation .main .company ol li.spacer{height:5px;padding:0;}
                .navigation .main .company ol li.link{padding:8px 15px;}
                    .navigation .main .company ol li.link a{display:block;clear:both;text-decoration:none;line-height:20px;font-size:10pt;color:#333;}
                    .navigation .main .company ol li.link a:hover{color:rgb(43,136,192);}            
                .navigation .main .company ol li.switch{cursor: pointer;height:18px;padding:10px 15px;}
                    .navigation .main .company ol li.switch .avatar{display:block;float:left;height:20px;width:20px;padding:2px;background:rgb(238,153,163);border-radius:3px;line-height:20px;text-align:center;text-transform:uppercase;font-weight:bold;color:rgb(77,18,25);font-size:7pt;}
                    .navigation .main .company ol li.switch span{display:block;float:right;width:calc(100% - 30px);height:24px;line-height:24px;font-size:10pt;color:#333;}
                    .navigation .main .company ol li.switch:hover span{color:rgb(43,136,192);}
                .navigation .main .company ol li.add{padding:8px 15px;}
                    .navigation .main .company ol li.add a{display:block;clear:both;text-decoration:none;color:#333;}
                    .navigation .main .company ol li.add a:hover{color:rgb(43,136,192);}
                        .navigation .main .company ol li.add a svg{display:block;float:left;height:17px;width:24px;line-height:24px;font-size:14pt;margin:3px 0 0 0;}
                        .navigation .main .company ol li.add a span{display:block;float:right;height:24px;line-height:24px;width:calc(100% - 30px);font-size:10pt;} */

        /*Profile*/
        /* .navigation .main .profile{display:block;float:right;height:60px;width:40px;padding:0 10px;margin:0 10px 0 0;}
        .navigation .main .profile:hover{background:rgb(38, 121, 173);}
            .navigation .main .profile b{display:block;width:30px;height:30px;border-radius:20px;padding:5px;background:rgb(238,153,163);color:rgb(77,18,25);line-height:30px;text-align:center;font-size:10pt;cursor:pointer;margin:10px 0;}
            .navigation .main .profile:hover b{background:#FFF;}
            .navigation .main .profile ol{display:none;position:absolute;right:10px;top:60px;background:#FFF;border:1px solid #ccced2;width:100px;border-radius:0 0 3px 3px;}
            .navigation .main .profile:hover ol{display:block;}
                .navigation .main .profile ol li{display:block;clear:both;padding:15px;}
                .navigation .main .profile ol li.spacer{height:5px;padding:0;}
                .navigation .main .profile ol li.link{padding:8px 15px;}
                    .navigation .main .profile ol li.link a{display:block;clear:both;text-decoration:none;line-height:20px;font-size:10pt;color:#333;text-align:right;}
                    .navigation .main .profile ol li.link a:hover{color:rgb(43,136,192);} 

    .navigation .side{display:block;position:fixed;top:61px;left:0;height:100%;background:#FFF;border-right:1px solid #DDD;} */

        /*Links*/
        /* .navigation .side ul{display:block;float:left;margin:10px 0;}
            .navigation .side ul li{display:block;clear:both;}
                .navigation .side ul li a{display:block;clear:both;height:30px;line-height:30px;padding:10px;text-decoration:none;color:#555;width:40px;border-left:3px solid transparent;transition:all 0.3s;}
                .navigation .side ul li a.active{background:#f0f3ff;border-color:rgb(38, 121, 173);color:rgb(38, 121, 173);}
                .navigation .side ul li a:hover{background:#f0f3ff;}
                    .navigation .side ul li a svg{display:block;clear:both;height:30px;line-height:30px;font-size:14pt;margin:0 auto;} */

        