.login{display:flex;flex:1;flex-direction:row;clear:both;height:100%;background:rgb(47,113,217);}
    /*Stage section*/
    .login .stage{flex:1;position:relative;}
        .login .stage .text{display:block;position:absolute;bottom:0px;font-size:104pt;color:rgb(45,108,206);padding:30px;}    

    /*Side section*/
    .login .aside{flex:0 500px;background:#FFF;}
        .login .aside .inner{display:block;position:relative;padding:40px;top:50%;transform:translateY(-50%);}
            .login .aside .inner h1{display:block;clear:both;height:25px;line-height:25px;font-weight:300;font-size:16pt;padding:0 0 10px 0;color:#222;}
            .login .aside .inner p{display:block;clear:both;line-height:20px;font-weight:300;color:#222;margin:15px 0;}
            .login .aside .inner a{text-decoration:none;color:rgb(16,107,214);}
            .login .aside .inner a:hover{text-decoration:underline;}

            .login .aside .inner .group{display:block;clear:both;padding:10px 0;}
                .login .aside .inner .group p{display:block;clear:both;line-height:20px;line-height:20px;margin:0 0 5px 0;}
                .login .aside .inner .group .input{display:block;clear:both;box-sizing:border-box;line-height:20px;border:1px solid #DDD;border-radius:3px;background:#FFF;padding:10px;width:100%;color:#333;}
                .login .aside .inner .group .input:focus{outline:none;}

            .login .aside .inner .action-button.offset{margin:10px 0;}

    