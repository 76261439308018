/*Grid*/    
div.grid{display:block;clear:both;margin:20px;padding:0;}
    div.grid ul{display:flex;flex-direction:column;}
        div.grid ul li{display:flex;flex-direction:row;padding:12px 5px;border-radius:6px;margin:4px 0;}
            div.grid ul li > div{display:flex;flex:1;position:relative;margin:0 10px;flex-direction:row;}
                div.grid ul li div p{flex:1;line-height:20px;padding:0 20px 0 0;min-height:20px;}
                div.grid ul li div sup{display:block;position:absolute;top:0;right:0;width:15px;height:20px;line-height:20px;text-align:center;font-size:9pt;cursor: pointer;}
                div.grid ul li div:hover sup{color:rgb(38, 121, 173);}

        /*Header styles*/
        div.grid ul li.header{font-weight:400;background:#FFF;}
            div.grid ul li div{cursor:pointer;}

        /*Line styles*/
        div.grid ul li.item{background:#FFF;border:1px solid #DDD;color:#555;transition: all 0.5s;}
        div.grid ul li.item:hover{background:#f0f3ff;}
            div.grid ul li.item div p{padding:0;}
            div.grid ul li.item div sup{display:none;}
            div.grid ul li.item div .avatar{flex:0 0 24px;background:rgb(38, 121, 173);height:24px;width:24px;line-height:24px;border-radius:12px;font-size:8pt;text-align:center;margin:0 10px 0 0;color:#FFF;font-weight:400;}

        /*Common Styles*/
        div.grid ul li div.actions{flex:0 0 20px;}
            div.grid ul li div.actions a{display:block;clear:both;text-decoration:none;color:rgb(38, 121, 173);font-size:12pt;text-align:center;line-height:20px;}

        /*Skeleton*/
        div.grid .skeleton{position: relative;overflow: hidden;background-color: #EEE;}
        div.grid p.skeleton{border-radius:4px;height:25px;}
        div.grid .skeleton::after{position: absolute;top: 0;right: 0;bottom: 0;left: 0;transform: translateX(-100%);background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));-webkit-animation: shimmer 2s infinite;animation: shimmer 2s infinite;content: "";}

        /*Error style*/
        div.grid ul li.error{color:#555;padding:50px;}
            div.grid ul li.error > div{flex-direction: column;}
            div.grid ul li.error > div div{margin:0 auto;}
            div.grid ul li.error > div h3{text-align:center;font-size:14pt;line-height:30px;padding:0 0 20px 0;}
            div.grid ul li.error > div span{text-align:center;padding:0 100px;}

    /*Stats*/
    div.grid div.stats{display:block;float:left;height:22px;line-height:22px;margin:15px 10px;font-size:8pt;}

    /*Pagninator*/
    div.grid ol{display:block;float:right;border:1px solid #DDD;background:#FFF;border-radius:3px;margin:10px;}
        div.grid ol li{display:block;float:left;height:20px;min-width:15px;padding:5px;line-height:20px;border-right:1px solid #DDD;text-align:center;cursor: pointer;}
        div.grid ol li.skeleton{min-width:250px;}
        div.grid ol li.active{background:rgb(38, 121, 173);color:#FFF;}
        div.grid ol li:first-child{border-radius:3px 0 0 3px;}
        div.grid ol li:last-child{border:none;border-radius:0 3px 3px 0;}