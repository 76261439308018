/*Button*/
.drop-button{display:block;float:right;position:relative;height:34px;padding:0;border:1px solid #DDD;background:#FFF;border-radius:4px;cursor:pointer;min-width:150px;margin:0 0 0 20px;}
.drop-button.actions{border-color:rgb(32, 117, 170);background:rgb(43,136,192);}
    .drop-button .inner{display:flex;flex-direction:row;}
        .drop-button .inner a{flex:1;padding:6px 10px;height:20px;line-height:20px;text-decoration:none;color:#555;}
        .drop-button.actions .inner a{color:#FFF;}
        .drop-button .inner a:hover{background:#f9f9f9;}
        .drop-button.actions .inner a:hover{background:rgb(32, 117, 170);}
        .drop-button .inner .drop{flex:0 10px;}
        .drop-button .inner .drop:hover{background:#f9f9f9;}
        .drop-button.actions .inner .drop:hover{background:rgb(32, 117, 170);}
            .drop-button .inner .drop svg{flex:1;height:34px;font-size:8pt;text-align:center;padding:0 10px;border-left:1px solid #DDD;}    
            .drop-button.actions .inner .drop svg{border-color:rgb(32, 117, 170);color:#FFF;}
        .drop-button .inner .drop ol{display:none;position:absolute;top:34px;left:-1px;right:-1px;background:#FFF;border:1px solid #DDD;border-radius:0 0 4px 4px;}
        .drop-button.actions .inner .drop ol{border-color:rgb(32, 117, 170);background:rgb(43,136,192);}
        .drop-button .inner .drop:hover ol{display:block;}
            .drop-button .inner .drop ol li{display:block;clear:both;border-bottom:1px solid #DDD;}
            .drop-button.actions .inner .drop ol li{border-color:rgb(32, 117, 170);}
            .drop-button .inner .drop ol li:last-child{border:none;border-radius:0 0 4px 4px;}
                .drop-button .inner .drop ol li a{display:block;clear:both;height:20px;padding:8px 10px;text-decoration:none;}