
/*Layout*/
.view .columns{display:flex;flex-direction:row;}
    .view .columns .col{flex:1;padding:20px;}
    .view .gutter{display:block;clear:both;height:20px;}

    /*Preview*/
    .view .columns .col.preview{border-radius:6px;margin:20px;background:#DDD;}
    
    /*From/To styles*/
    .view .columns .col.from{padding:0 20px 0 0;border-right:1px solid #DDD;}
    .view .columns .col.to{padding:0 0 0 20px;}

    /*Headings*/
    .view .columns .col h4{display:block;clear:both;height:20px;line-height:20px;font-size:11pt;font-weight:400;}

    /*Card*/
    .card{display:block;position:relative;clear:both;height:60px;background:#FFF;border:1px solid #DDD;padding:14px 57px 14px 88px;margin:20px 0;border-radius:4px;cursor: pointer;}
        .card .avatar{display:block;position:absolute;top:14px;left:14px;width:60px;height:20px;padding:20px 0;border-radius:6px;background:rgb(238,153,163);line-height:20px;text-align:center;text-transform:uppercase;font-weight:bold;color:rgb(77,18,25);font-size:11pt;}
        .card span{display:block;clear:both;height:25px;line-height:25px;}
        .card span:first-of-type{font-size:10pt;font-weight:400;padding:5px 0 0 0;}
        .card svg{display:block;position:absolute;top:0;right:0;width:15px !important;height:20px;font-size:10pt;padding:34px 14px;}

    /*Form Row*/
    .form-row{display:flex;flex-direction:column;margin:10px 16px;}
        .form-row span{flex:1;height:20px;line-height:20px;color:#777;}
        .form-row p{flex:1;line-height:20px;color:#555;}

    /*Table*/
    .table{display:block;clear:both;background:#FFF;border:1px solid #DDD;border-radius:6px;margin:20px 0 0 0;}
        .table ol{display:block;clear:both;}
            .table ol li{display:flex;flex-direction:row;border-bottom:1px solid #DDD;padding:0;}
            .table ol li:last-child{border:none;}
            .table ol li.alt{background:#f9f9f9;}
                .table ol li div{flex:1;padding:12px 15px;border-right:1px solid #DDD;}
                .table ol li div:last-child{border:none;}
                .table ol li.header div p{font-weight:400;}
