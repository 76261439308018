/*Toolbar*/
.view .toolbar{display:flex;flex-direction:row;background:#FFF;border-bottom:1px solid #DDD;padding:20px;}
    .view .toolbar .breadcrumbs{flex:1;}
        .view .toolbar .breadcrumbs ul{display:block;clear:both;height:30px;}
            .view .toolbar .breadcrumbs ul li{display:block;float:left;height:20px;padding:5px 10px 5px 0;}
                .view .toolbar .breadcrumbs ul li a{color:rgb(38, 121, 173);text-decoration:none;}
                .view .toolbar .breadcrumbs ul li a:hover{text-decoration:underline;}
                .view .toolbar .breadcrumbs ul li:last-child{font-weight:400;}

    .view .toolbar .controls{flex:1;}


                    
            