app{display:flex;flex-direction:row;position:relative;height:100%;}

    /*Loading*/
    app .loading{display:block;position:relative;clear:both;top:50%;height:300px;background:red;transform:translateY(-50%);}

    /*Container*/
    app .container{display:flex;flex:1;flex-direction:row;height:100%;background:#FFF;}
        app .container .view{display:block;flex:1;font-weight:300;font-size:10pt;line-height:20px;}
        app .container.auth .view{padding:61px 0 0 64px;}
        app .container .copyright{display:block;flex:0 0 70px;text-align:center;color:#666;line-height:24px;font-weight:300;font-size:9pt;}
            app .container .copyright svg{color:rgb(38, 121, 173);}
            app .container .copyright a{color:rgb(38, 121, 173);text-decoration:none;}
            app .container .copyright a:hover{text-decoration:underline;}